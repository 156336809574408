import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BadgeProps, BadgeVariant, StyledBadgeBox } from '../Badge/Badge';
import { buildBadgeIconConfig } from '../Badge/variants';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';

export type Props = BadgeProps & {
  size?: 'small' | 'regular';
};

const NotificationContainer = styled(StyledBadgeBox)<{
  variant: BadgeVariant;
}>(
  ({ theme, variant }) => css`
    border-left: 5px solid ${theme.colours.border[variant]};
  `,
);

const Notification = ({
  children,
  icon,
  size = 'regular',
  variant = 'information',
  ...props
}: Props) => {
  return (
    <NotificationContainer
      {...props}
      alignItems="center"
      borderRadius="borderRadiusS"
      display="flex"
      gap={size === 'regular' ? 16 : 8}
      p={size === 'regular' ? 16 : 8}
      variant={variant}
    >
      {icon && (
        <Box flex="0 0 auto">
          <Icon icon={icon} size={20} {...buildBadgeIconConfig(variant)} />
        </Box>
      )}
      {children}
    </NotificationContainer>
  );
};

export default Notification;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TypographyV4Fragment } from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import { centerVertically, createTypography } from '../../styles/base';
import MediaQuery from '../Responsive/MediaQuery';

type TypographyVariant =
  | 'body1'
  | 'body2'
  | 'button'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6';

export interface Props {
  children: React.ReactNode;
  className?: string;
  dataCy?: string;
  headingStyle?: TypographyVariant;
  icon?: Maybe<JSX.Element>;
}

const legacyTypographyVariantMapper: Record<
  TypographyVariant,
  keyof TypographyV4Fragment
> = {
  body1: 'body01',
  body2: 'body02',
  button: 'heading05',
  h1: 'heading01',
  h2: 'heading02',
  h3: 'heading03',
  h4: 'heading04',
  h5: 'heading05',
  h6: 'heading06',
};

const Container = styled.div(centerVertically);

const IconContainer = styled.div`
  margin-right: 10px;
`;

export const Heading = styled.h2<{ headingStyle: TypographyVariant }>(
  ({ headingStyle, theme: { colours, typography } }) => [
    createTypography(typography[legacyTypographyVariantMapper[headingStyle]]),
    css`
      color: ${colours.text.default};
    `,
  ],
);

const Title = ({
  children,
  className,
  dataCy,
  headingStyle = 'h2',
  icon,
}: Props) => {
  return (
    <Container className={className} data-cy={dataCy}>
      <MediaQuery fromDisplay={DisplayType.Small}>
        {icon && <IconContainer>{icon}</IconContainer>}
      </MediaQuery>
      <Heading headingStyle={headingStyle}>{children}</Heading>
    </Container>
  );
};

export default Title;

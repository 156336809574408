import { ButtonHTMLAttributes, AriaAttributes } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { legacySpacings } from '@ui-v2/theme/layout';
import { createTypography, loadingAnimation } from '../../styles/base';
import { resetButton } from '../../styles/cssReset';

export enum ButtonType {
  PRIMARY = 'primary',
  RAW = 'raw',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum ButtonSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: AriaAttributes['aria-label'];
  buttonSize?: ButtonSize;
  buttonType?: Maybe<ButtonType>;
  className?: string;
  dataCy?: string;
  isLoading?: boolean;
}

const getButtonPadding = (
  spacings: Theme['spacings'],
  buttonSize?: ButtonSize,
) => {
  switch (buttonSize) {
    case ButtonSize.Small:
      return `${spacings['8']}px ${spacings['16']}px`;
    case ButtonSize.Large:
      return `${spacings['16']}px ${spacings['24']}px`;
    case ButtonSize.Medium:
    default:
      return `
        12px ${legacySpacings.Medium}px
      `;
  }
};

export const StyledButton = styled.button<
  Pick<Props, 'buttonSize' | 'buttonType' | 'isLoading' | 'disabled'>
>(
  ({
    buttonSize,
    buttonType,
    isLoading,
    theme: { colours, shape, spacings, typography },
  }) => {
    const isRaw = buttonType === ButtonType.RAW;

    return [
      resetButton,
      css`
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      `,
      isRaw &&
        css`
          ${createTypography(typography.heading05)};
        `,
      !isRaw &&
        css`
          ${createTypography(typography.heading04)};
          display: block;
          width: 100%;
          padding: ${getButtonPadding(spacings, buttonSize)};
          border-radius: ${shape.buttonPrimaryBorderRadius}px;
          background: ${colours.interactive.primary.default};
          color: ${colours.text.on.interactive.primary.default};
          text-align: center;
          text-decoration: none;
          user-select: none;

          &[aria-disabled='true'],
          &[aria-disabled='true']:hover,
          &[aria-disabled='true']:focus {
            border-color: ${colours.text.disabled};
            cursor: not-allowed;
            opacity: 0.7;
          }

          &:hover {
            background: ${colours.interactive.primary.hover};
          }

          &:active,
          &:focus {
            background: ${colours.interactive.primary.pressed};
          }

          &:disabled:hover {
            background: ${colours.interactive.primary.default};
            color: ${colours.text.on.interactive.primary.default};
          }
        `,
      buttonType === ButtonType.SECONDARY &&
        css`
          border: 1.5px solid ${colours.border.interactive};
          border-radius: ${shape.buttonSecondaryBorderradius}px;
          background: ${colours.interactive.secondary.default};
          color: ${colours.text.on.interactive.secondary.default};

          &:hover {
            background: ${colours.interactive.secondary.hover};
          }

          &:active,
          &:focus {
            background: ${colours.interactive.secondary.pressed};
          }

          &:disabled:hover {
            background: ${colours.interactive.secondary.default};
            color: ${colours.text.on.interactive.secondary.default};
          }
        `,
      buttonType === ButtonType.TERTIARY &&
        css`
          border-radius: ${shape.buttonTertiaryBorderRadius}px;
          background: unset;
          color: ${colours.text.on.interactive.tertiary.default};

          &:hover {
            background: ${colours.interactive.tertiary.hover};
            color: ${colours.text.on.interactive.tertiary.default};
          }

          &:active,
          &:focus {
            background: unset;
            color: ${colours.text.on.interactive.tertiary.default};
          }

          &:disabled:hover {
            background: unset;
            color: ${colours.text.on.interactive.tertiary.default};
          }
        `,
      isLoading &&
        buttonType &&
        buttonType !== ButtonType.RAW &&
        loadingAnimation(
          16,
          buttonType === ButtonType.PRIMARY ||
            buttonType === ButtonType.SECONDARY
            ? colours.text.on.interactive[buttonType].default
            : colours.text.default,
        ),
    ];
  },
);

const Button = ({
  buttonSize = ButtonSize.Medium,
  buttonType = ButtonType.PRIMARY,
  children,
  className,
  dataCy,
  disabled,
  isLoading,
  onClick,
  ...props
}: Props) => (
  <StyledButton
    aria-disabled={disabled || isLoading ? 'true' : 'false'}
    buttonSize={buttonSize}
    buttonType={buttonType}
    className={className}
    data-cy={dataCy}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    onClick={!disabled ? onClick : undefined}
    {...props}
  >
    {children}
  </StyledButton>
);

export default Button;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { loadingAnimation } from '../../styles/base';

export interface Props {
  className?: string;
  diameter: number;
}

const LoadingContainer = styled.div<{ diameter: number }>(
  ({ diameter, theme: { colours } }) => [
    loadingAnimation(diameter, colours.brand.primary),
    css`
      min-width: ${diameter}px;
      min-height: ${diameter}px;
    `,
  ],
);

const Loading = ({ className, diameter }: Props) => {
  return <LoadingContainer className={className} diameter={diameter} />;
};

export default Loading;
